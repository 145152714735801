import * as React from "react";
import Layout from "../../components/Navigation/layout";

const IndexPage = () => {
    return (
        <Layout pageTitle="Datenschutz">

        </Layout>
    )
}

export default IndexPage;